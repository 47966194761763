import { IBaseAction } from "../../../shared/types/baseActionTypes"
import { ITerms } from "../../account/state/accountTypes"
import { ILiveMeetingUserConfig } from "../../liveMeeting/state/liveMeetingTypes"
import { ISpecialty } from "../../specialty/state/specialtyTypes"

// State
export enum SignInType {
  Local = "local",
  NotFound = "not_found"
}

export enum UserTitles {
  Mr = "Mr",
  Mrs = "Mrs",
  Ms = "Ms",
  Miss = "Miss",
  Mx = "Mx",
  Dr = "Dr",
  Prof = "Prof"
}

export enum UserGenders {
  Unknown = "unknown",
  Unspecified = "unspecified",
  Male = "male",
  Female = "female"
}

export interface IUserPermissions {
  canEdit: boolean
  canManage: boolean
  canTrackChanges: boolean
  canAssignAdminRole: boolean
}

export interface IUserAbility {
  canCreate?: boolean
  canUpload?: boolean
  canManage?: boolean
  canList?: boolean
}

export interface IUserAbilities {
  case: IUserAbility
  patient: IUserAbility
  meeting: IUserAbility
  user: IUserAbility
  team: IUserAbility
  tenant: IUserAbility
  radiology: IUserAbility
}

export interface ITenantUser {
  active: boolean
  tenant: ITenant
}
export interface ITenant {
  id: string
  name: string
}

export interface IUser {
  id: string
  config?: ILiveMeetingUserConfig
  title: string
  professionalTitle: string
  firstName: string
  lastName: string
  email?: string
  phoneNumber?: string
  phoneCountryCode?: string
  organisation?: string
  pending: boolean
  local?: boolean
  onlineInCurrentTenant: boolean
  lastSeenInCurrentTenant: string
  cases?: string[]
  roles?: string[]
  tenantRoles?: string[]
  tenantGuest?: boolean
  specialties?: ISpecialty[]
  permissions?: IUserPermissions
  abilities?: IUserAbilities
  activeInCurrentTenant?: boolean
  mfaMethods?: string[]
  mfaRequired?: boolean
  tenantUsers?: ITenantUser[]
  currentTenantId?: string
  mfaEnabled?: boolean
  existsInCurrentTenant?: boolean
  accessLocked?: boolean
}

export interface IUserSessionState {
  id: string
  apiToken: string
  signInType: SignInType
  isLoading: boolean
  error: string
  terms: ITerms
  mfa: {
    sms: {
      requesting: boolean
    }
  }
}

export interface IUserNewData {
  title?: string
  professionalTitle?: string
  firstName: string
  lastName: string
  email: string
  specialtyIds?: string[]
  local?: boolean
}

export interface IUserEditData {
  title?: string
  professionalTitle?: string
  firstName: string
  lastName: string
  specialtyIds?: string[]
  admin_roles?: string[]
  active: boolean
  mfaEnabled: boolean
  email: string
  phoneNumber?: string
  phoneCountryCode?: string
  organisation?: string
}

export interface IGuestUserNewFormData {
  title?: string
  professionalTitle?: string
  firstName: string
  lastName: string
  email: string
  specialtyIds?: string[]
  phoneNumber: string
  organisation?: string
}

export interface IGuestUserNewData {
  title?: string
  professionalTitle?: string
  firstName: string
  lastName: string
  email: string
  specialtyIds?: string[]
  phoneNumber: string
  phoneCountryCode: string
  organisation?: string
}

export interface IGuestUserInviteExistingFormData {
  guestUserIds: string[]
}

export interface IHaveUserId {
  userId: string
}

export interface IHaveUserIdParam {
  match?: {
    params: {
      userId: string
    }
  }
}

export enum UserCheckStatuses {
  Pending = "pending",
  Found = "found",
  Not_Found = "not_found"
}

export interface IUserCheckData {
  firstName: string
  lastName: string
  email: string
}

export interface IUserCheckUIState {
  existingUserIds: string[]
  status: string
}

// Actions
export interface IUserCreateUserAsyncAction extends IBaseAction {
  payload: IUserNewData
}
export interface IUserAddToTenantAction extends IBaseAction {
  payload: { id: string }
}

export interface IUpdateUserAsyncAction extends IBaseAction {
  payload: {
    id: string
    user: IUserEditData
  }
}

export interface ISetTenantAction extends IBaseAction {
  payload: {
    id: string
    path?: string
  }
}

export interface IFetchUserAsyncAction extends IBaseAction {
  payload: string
}

export interface ICheckUserAction extends IBaseAction {
  payload: {
    userCheckData: IUserCheckData
  }
}

// Action Types
export enum UserActionTypes {
  CREATE_USER_ASYNC_PENDING = "@@user/CREATE_USER_ASYNC_REQUEST",
  CREATE_USER_ASYNC_SUCCESS = "@@user/CREATE_USER_ASYNC_SUCCESS",
  CREATE_USER_ASYNC_ERROR = "@@user/CREATE_USER_ASYNC_ERROR",
  ADD_USER_TO_TENANT_ASYNC_PENDING = "@@user/ADD_USER_TO_TENANT_ASYNC_REQUEST",
  ADD_USER_TO_TENANT_ASYNC_SUCCESS = "@@user/ADD_USER_TO_TENANT_ASYNC_SUCCESS",
  ADD_USER_TO_TENANT_ASYNC_ERROR = "@@user/ADD_USER_TO_TENANT_ASYNC_ERROR",
  UPDATE_USER_ASYNC_PENDING = "@@user/UPDATE_USER_ASYNC_REQUEST",
  UPDATE_USER_ASYNC_SUCCESS = "@@user/UPDATE_USER_ASYNC_SUCCESS",
  UPDATE_USER_ASYNC_ERROR = "@@user/UPDATE_USER_ASYNC_ERROR",
  FETCH_USER_ASYNC_PENDING = "@@user/FETCH_USER_ASYNC_REQUEST",
  FETCH_USER_ASYNC_SUCCESS = "@@user/FETCH_USER_ASYNC_SUCCESS",
  FETCH_USER_ASYNC_ERROR = "@@user/FETCH_USER_ASYNC_ERROR",
  SET_TENANT_ASYNC_PENDING = "@@user/SET_TENANT_ASYNC_REQUEST",
  SET_TENANT_ASYNC_SUCCESS = "@@user/SET_TENANT_ASYNC_SUCCESS",
  SET_TENANT_ASYNC_ERROR = "@@user/SET_TENANT_ASYNC_ERROR",

  RESEND_INVITE_ASYNC_PENDING = "@@user/RESEND_INVITE_ASYNC_REQUEST",
  CLEAR_MFA_ASYNC_PENDING = "@@user/CLEAR_MFA_ASYNC_REQUEST",

  // FETCH_CURRENT_USER hooks back into tho FETCH_USER_ASYNC_SUCCESS events, as they return the same data
  // for that reason, there are no SUCCESS or ERROR actions
  FETCH_CURRENT_USER_ASYNC_PENDING = "@@user/FETCH_CURRENT_USER_ASYNC_REQUEST",

  CHECK_USER_ASYNC_RESET = "@@user/CHECK_USER_RESET",
  CHECK_USER_ASYNC_PENDING = "@@user/CHECK_USER_ASYNC_PENDING",
  CHECK_USER_ASYNC_FOUND = "@@user/CHECK_USER_ASYNC_FOUND",
  CHECK_USER_ASYNC_NOT_FOUND = "@@user/CHECK_USER_ASYNC_NOT_FOUND",

  FETCH_EXTERNAL_ACCOUNTS_ASYNC_PENDING = "@@user/FETCH_EXTERNAL_ACCOUNTS_ASYNC_REQUEST",
  FETCH_EXTERNAL_ACCOUNTS_ASYNC_SUCCESS = "@@user/FETCH_EXTERNAL_ACCOUNTS_ASYNC_SUCCESS",
  FETCH_EXTERNAL_ACCOUNTS_ASYNC_ERROR = "@@user/FETCH_EXTERNAL_ACCOUNTS_ASYNC_ERROR",

}

export const UserChannelName = "UserChannel"
export const userCablePrefix = "@@userCable/"
