import { put, call, Effect, takeEvery, takeLeading, select } from "redux-saga/effects"
import { ISetTenantAction, IUserAbilities, UserActionTypes } from "../userTypes"
import API from "../../../../apis/contextmeeting/api"
import { displayUserMessageAction } from "../../../userMessage/state/userMessageActions"
import { UserMessageTypes } from "../../../userMessage/state/userMessageTypes"
import { withAuthHeader } from "../../../../apis/contextmeeting/withAuthHeader"
import { RoutePaths } from "../../../../app/routes/Routes"
import history from "../../../../app/routes/history"
import { normalize } from "normalizr"
import { userSchema } from "../../../../apis/contextmeeting/schema"
import { fetchTenantConfigAsync } from "../../../tenantConfig/state/tenantConfigActions"
import { handleError, handleSuccess } from "../../../../shared/helpers/sagaHelpers"
import { getCurrentUserAbilities } from "../../../../shared/selectors/user"

export function* watchUserActions(): Generator {
  yield takeLeading(UserActionTypes.CREATE_USER_ASYNC_PENDING, createUserAsync)
  yield takeLeading(UserActionTypes.ADD_USER_TO_TENANT_ASYNC_PENDING, addUserToTenantAsync)
  yield takeLeading(UserActionTypes.SET_TENANT_ASYNC_PENDING, setTenantAsync)
  yield takeEvery(UserActionTypes.FETCH_USER_ASYNC_PENDING, fetchUserAsync)
  yield takeLeading(UserActionTypes.UPDATE_USER_ASYNC_PENDING, updateUserAsync)
  yield takeLeading(UserActionTypes.FETCH_CURRENT_USER_ASYNC_PENDING, fetchCurrentUserAsync)
  yield takeLeading(UserActionTypes.FETCH_EXTERNAL_ACCOUNTS_ASYNC_PENDING, fetchExternalAccountsAsync)
}

export function* createUserAsync(action: Effect): Generator {
  const user = {
    user: action.payload
  }

  try {
    const response: any = yield call(API.post, "/users", user, withAuthHeader())
    yield handleSuccess(UserActionTypes.CREATE_USER_ASYNC_SUCCESS, response.data, "createUserSuccess")
    yield call(history.push, RoutePaths.USERS)
  } catch (error) {
    yield handleError(UserActionTypes.CREATE_USER_ASYNC_ERROR, error, "createUserFail")
  }
}

export function* addUserToTenantAsync(action: Effect): Generator {
  try {
    // we don't need to handle the response: as long as it was a success, we
    // redirect to the user inde page which will reload the users regardless
    yield call(API.post, "/tenant_users", action.payload, withAuthHeader())
    yield call(history.push, RoutePaths.USERS)
  } catch (error) {
    yield handleError(UserActionTypes.CREATE_USER_ASYNC_ERROR, error, "addUserToTenantFail")
  }
}

export function* fetchUserAsync(action: Effect): Generator {
  try {
    const userId = action.payload

    const response: any = yield call(API.get, `/users/${userId}`, withAuthHeader())
    const normalizedResponse = normalize(response.data, userSchema)

    yield put({
      type: UserActionTypes.FETCH_USER_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

  } catch (error) {
    yield put({
      type: UserActionTypes.FETCH_USER_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "userNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* fetchCurrentUserAsync(): Generator {
  try {
    const response: any = yield call(API.get, "/users/current", withAuthHeader())

    const normalizedResponse = normalize(response.data, userSchema)

    // this saga actually hooks into the normal FETCH_USER_ASYNC_SUCCESS
    // reducers as it is returning the same data.
    yield put({
      type: UserActionTypes.FETCH_USER_ASYNC_SUCCESS,
      payload: normalizedResponse
    })

  } catch (error) {
    yield put({
      type: UserActionTypes.FETCH_USER_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "userNotFound",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* updateUserAsync(action: Effect): Generator {
  const user = {
    user: action.payload.user
  }

  try {
    const response: any = yield call(API.patch, `/users/${action.payload.id}`, user, withAuthHeader())
    yield handleSuccess(UserActionTypes.UPDATE_USER_ASYNC_SUCCESS, response.data, "updateUserSuccess" )

    const abilities = yield select(getCurrentUserAbilities)
    if ((abilities as IUserAbilities).user.canList){
      yield call(history.push, RoutePaths.USERS)
    } else {
      yield call(history.push, RoutePaths.MEETINGS)
    }

  } catch (error) {
    yield handleError(UserActionTypes.UPDATE_USER_ASYNC_ERROR, error, "updateUserFail")
  }
}


export function* setTenantAsync(action: ISetTenantAction): Generator {
  try {
    yield call(API.patch, `/user/set_tenant/${action.payload.id}`, {}, withAuthHeader())

    yield put({
      type: UserActionTypes.SET_TENANT_ASYNC_SUCCESS,
    })

    yield put(displayUserMessageAction({
      messageKey: "switchedTenant",
      type: UserMessageTypes.SUCCESS
    }))

    yield put(fetchTenantConfigAsync())

    yield call(history.push, action.payload.path || RoutePaths.MEETINGS)

  } catch (error) {
    yield put({
      type: UserActionTypes.SET_TENANT_ASYNC_ERROR,
      payload: error
    })

    yield put(displayUserMessageAction({
      messageKey: "switchTenantFail",
      type: UserMessageTypes.ERROR
    }))
  }
}

export function* fetchExternalAccountsAsync(): Generator {
  try {
    const response: any = yield call(API.get, "/users/find_external", withAuthHeader())
    yield handleSuccess(UserActionTypes.FETCH_EXTERNAL_ACCOUNTS_ASYNC_SUCCESS, response.data)
  } catch (error) {
    yield handleError(UserActionTypes.FETCH_EXTERNAL_ACCOUNTS_ASYNC_ERROR, error)
  }

}
